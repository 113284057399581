.btn {
  font-weight: 500;

  &.btn-blue,
  &.btn-primary,
  &.btn-red,
  &.btn-danger {
    border-radius: 4px;
    color: #fff;

    &:disabled {
      opacity: 1;
    }
  }

  &.btn-blue,
  &.btn-primary {
    border-color: $blue-40;
    background: $blue-40;

    &:hover {
      border-color: $blue-60;
      background: $blue-60;
    }

    &:disabled {
      color: $grey-6;
      border-color: $blue-10;
      background: $blue-10;
    }

    &:active,
    &.active {
      border-color: $blue-30;
      background: $blue-30;
    }
    &:focus {
      outline: 2px solid $blue-20;
    }
  }

  &.btn-red,
  &.btn-danger {
    border-color: $red-80;
    background: $red-80;

    &:hover {
      border-color: $red-100;
      background: $red-100;
    }

    &:disabled {
      color: $grey-6;
      border-color: $red-10;
      background: $red-10;
    }

    &:active,
    &.active {
      border-color: $red-60;
      background: $red-60;
    }
  }

  &.btn-orange,
  &.btn-warning {
    color: #fff;
    border-color: $orange-80;
    background: $orange-80;

    &:hover {
      border-color: $orange-100;
      background: $orange-100;
    }

    &:disabled {
      color: #fff;
      border-color: $orange-10;
      background: $orange-10;
    }

    &:active,
    &.active {
      border-color: $orange-60;
      background: $orange-60;
    }
  }

  &.btn-white {
    border-color: #fff;
    background: #fff;
    color: $blue-40;

    &:hover {
      color: #fff;
      border-color: $blue-30;
      background: $blue-30;
    }

    &:disabled {
      color: $blue-40;
      border-color: $grey-6;
      background: $grey-6;
    }

    &:active,
    &.active {
      color: #fff;
      border-color: $blue-30;
      background: $blue-30;
    }
  }

  &.btn-secondary,
  &.btn-outline-secondary {
    border-color: $blue-40;
    background: #fff;
    color: $blue-40;

    &:not(.dropdown-toggle):hover,
    &.dropdown-toggle[aria-expanded="false"]:hover {
      background: $blue-6;
    }

    &:disabled {
      color: $blue-10;
      border-color: $grey-10;
      background: $grey-6;
    }

    &:active,
    &.active {
      color: $blue-30 !important;
      border-color: $blue-30 !important;
      background: $blue-8 !important;
    }
  }

  &.btn-switch {
    background: #fff;
    color: $grey-80;
    border-color: $grey-10;
    font-weight: 500;

    &:hover {
      background: $blue-6;
    }

    &:active,
    &.active {
      background: $blue-6;
    }

    &.active::before {
      font-family: "feather" !important;
      content: "\e83f";
      margin-right: 0.375rem;
    }
  }

  &.btn-toggle,
  &.btn-toggle-grey,
  &.btn-toggle-yellow,
  &.btn-toggle-orange,
  &.btn-toggle-purple,
  &.btn-toggle-violet,
  &.btn-toggle-green,
  &.btn-toggle-red,
  &.btn-toggle-red-grey,
  &.btn-toggle-blue {
    border-radius: 4px;
    color: $blue-100;

    &:active,
    &.active,
    &:focus {
      color: $blue-100;
    }

    &:disabled {
      color: $grey-30;
      background: $grey-6;
    }
  }

  &.btn-toggle {
    border-color: $blue-8;
    border-left-width: 1px;
    color: $blue-80;

    &:hover {
      border: 1px solid $blue-10;
      background: $blue-6;
    }

    &:active,
    &.active {
      color: $blue-80;
      border-color: $blue-40;
      background: $blue-6;
    }

    &:disabled {
      border-color: $grey-10;

      i {
        color: $blue-20;
      }
    }
  }

  &.btn-toggle-grey {
    border: 1px solid $grey-40;

    &:hover {
      background: $grey-6;
    }

    &:active,
    &.active {
      border-color: $grey-40;
      background: $grey-40;
    }

    &:disabled {
      border-color: $grey-10;
    }
  }

  &.btn-toggle-blue {
    border: 1px solid $blue-20;

    &:hover {
      border: 1px solid $blue-20;
      background: $blue-6;
    }

    &:active,
    &.active {
      border-color: $blue-15;
      background: $blue-15;
    }

    &:disabled {
      border-color: $blue-10;
    }
  }

  &.btn-toggle-orange {
    border: 1px solid $orange-60;

    &:hover {
      background: $orange-6;
    }

    &:active,
    &.active {
      border-color: $orange-40;
      background: $orange-40;
    }

    &:disabled {
      border-color: $orange-10;
    }
  }

  &.btn-toggle-purple {
    border: 1px solid $purple-40;

    &:hover {
      background: $purple-6;
    }

    &:active,
    &.active {
      border-color: $purple-20;
      background: $purple-20;
    }

    &:disabled {
      border-color: $purple-10;
    }
  }

  &.btn-toggle-violet {
    border: 1px solid #75629C;

    &:hover {
      background: #f1eff5;
    }

    &:active,
    &.active {
      border-color: #aca0c3;
      background:#aca0c3;
    }

    &:disabled {
      border-color: #c7c0d7;
    }
  }

  &.btn-toggle-green {
    border: 1px solid $green-40;

    &:hover {
      background: $green-6;
    }

    &:active,
    &.active,
    &:focus {
      border-color: $green-20;
      background: $green-20;
    }

    &:disabled {
      border-color: $green-10;
    }
  }

  &.btn-toggle-red,
  &.btn-toggle-red-grey {
    border: 1px solid $red-60;

    &:hover {
      background: $red-6;
    }

    &:active,
    &.active,
    &:focus {
      border-color: $red-60;
      background: $red-60;
    }

    &:disabled {
      color: $grey-30;
      border-color: $red-10;
      background: $grey-6;
    }
  }

  &.btn-toggle-white {
    border: 1px solid #fff;
    color: #fff;

    &:hover {
      background: #fff;
      color: $blue-40;
    }
  }

  &.btn-action,
  &.btn-action-red,
  &.btn-action-toggle {
    border: 1px solid transparent;
    background: #fff;
    border-radius: 4px;
  }

  &.btn-action {
    background: #fff;
    color: $blue-40;
    box-shadow: 0 1px 4px 0 #00000029;

    &:hover {
      background: $blue-8;
      box-shadow: 0 1px 4px 0 #00000029;
    }

    &.active {
      color: #fff;
      background: $blue-30;
    }

    &:disabled {
      background: $grey-6;
      color: $grey-30;
    }
  }

  &.btn-action-red {
    background: $red-80;
    color: #fff;

    &:hover {
      background: $red-100;
    }

    &:active,
    &.active {
      background: $red-100;
    }

    &:disabled {
      background: $red-10;
      color: #fff;
    }
  }

  &.btn-action-toggle {
    border: 1px solid $blue-8;
    color: $grey-80;

    &:hover {
      background: $blue-8;
      border-color: $blue-8;
      color: $grey-80;
    }

    &.active {
      background: $blue-6;
      border-color: $blue-20;
      color: $blue-40;
    }

    &:disabled {
      background: $grey-6;
      border: 1px solid $grey-8;
      color: $grey-30;
    }

    &.active:disabled {
      background: $blue-6;
      border-color: $blue-8;
      color: $blue-20;
    }

    &.btn-sm {
      padding: 0;
      width: 32px;
      height: 32px;
      line-height: 1;

      i {
        font-size: 16px;
      }
    }

    &.btn-xs {
      padding: 0;
      width: 24px;
      height: 24px;

      i {
        font-size: 13px;
      }
    }
  }

  &.btn-outline-orange {
    color: $orange-80;
    border: 1px solid $orange-60;
    background: #fff;

    &:hover {
      color: $orange-80;
      border-color: $orange-60;
      background: $orange-6;
    }

    &:focus {
      color: $orange-60;
      outline: 2px solid $orange-10;
      background: $orange-6;
    }

    &:active,
    &.active {
      color: $orange-80;
      border-color: $orange-40;
      background: $orange-4;
    }

    &:disabled {
      color: $orange-10;
      border-color: $orange-10;
      background: $grey-6;
    }
  }

  &.btn-outline-red {
    color: $red-80;
    border: 1px solid $red-40;
    background: #fff;

    &:hover {
      color: $red-80;
      border-color: $red-60;
      background: $red-6;
    }

    &:focus {
      color: $red-60;
      outline: 2px solid $red-10;
      background: $red-6;
    }

    &:active,
    &.active {
      color: $red-80;
      border-color: $red-40;
      background: $red-4;
    }

    &:disabled {
      color: $red-10;
      border-color: $red-10;
      background: $red-6;
    }
  }
}

.status-filter.btn-group {
  .btn {
    border-radius: 4px !important;
    margin-right: 4px;
    border-left-width: 0.35rem;

    &.btn-toggle {
      border-left-width: 1px;
    }

    &.btn-toggle-red-grey {
      border-left-width: 1px;
      padding-left: 1.1rem;

      &:disabled {
        padding-left: 0.9rem;
        color: $grey-30;
        border-left-width: 0.35rem;
      }

      &:active,
      &.active,
      &:focus {
        border-color: $red-40;
        color: $blue-100;
        background: linear-gradient(0deg, $red-40 46%, #fff 46%);

        &:not(:disabled)::before {
          background: linear-gradient(0deg, $red-40 46%, $grey-8 46%);
        }
      }

      &:not(:disabled)::before {
        content: "";
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        background: linear-gradient(0deg, $red-60 46%, $grey-8 46%);
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 5px;
      }
    }
  }
}

.btn-group {
  .btn-switch {
    min-width: 100px;
  }
}
